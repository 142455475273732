body {
  min-height: 100vh;
  max-width: 100vw;
  height: 100%;
  width: 100%;
  min-width: 340px;
  padding: 0;
  margin: 0;
  font-family: 'Georgia', Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
code {
  font-family: 'Courier New', monospace;
}

#root {
  height: var(--app-height);
  margin: 0;
  padding: 0;
}
